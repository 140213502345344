import xlsx, { utils as XLSX_Utils, read as XLSX_Read } from "xlsx";

export const convertJsonToExcel = (fileName, data) => {
  return new Promise((resolve, reject) => {
    try {
      var worksheet = XLSX_Utils.aoa_to_sheet(data);
      var new_workbook = XLSX_Utils.book_new();
      XLSX_Utils.book_append_sheet(new_workbook, worksheet, "Sheet");
      xlsx.writeFile(new_workbook, `${fileName}.xlsx`);
      resolve();
    } catch (error) {
      reject();
    }
  });
};

export const convertExcelToJson = (file) => {
  console.log(file);
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (e) => {
        try {
          const workbook = xlsx.read(e.target.result, {
            type: "binary",
            cellDates: true,
          });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          let data = [...xlsx.utils.sheet_to_json(worksheet, { raw: false })];
          let dataRaw = [...xlsx.utils.sheet_to_json(worksheet, { raw: true })];

          resolve(data);
        } catch (error) {
          console.log(error);
        }
      };
    } catch (error) {
      console.log(error);
      reject();
    }
  });
};
