function getAutoEndWhen(autoEnd) {
    if (autoEnd?.config?.reachParams?.geofence_out?.trip_changes?.endsIn) return "geofence_out"
    return "geofence_in"
}

function getAutoEndTime(autoEnd) {
    if (autoEnd?.config?.reachParams?.geofence_out?.trip_changes?.endsIn) return autoEnd?.config?.reachParams?.geofence_out?.trip_changes?.endsIn
    if (autoEnd?.config?.reachParams?.geofence_in?.trip_changes?.endsIn) return autoEnd?.config?.reachParams?.geofence_in?.trip_changes?.endsIn
    return "1"
}

export default {
    getAutoEndWhen,
    getAutoEndTime,
}