import { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  Button,
  TextField,
  Typography,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  MenuItem,
  CircularProgress,
  Box,
  Tab,
  Tabs,
} from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import http from "../../http";
import moment from "moment";
import utils from "./utils";
import xlsx from "xlsx";
import axios from "axios";
import { Autocomplete } from "@material-ui/lab";
import { convertExcelToJson, convertJsonToExcel } from "../../utils/xlsxParser";

const headers = [
  { value: "client", id: "client", label: "Client" },
  { value: "client_client", id: "client_client", label: "Client_Client" },
  { value: "username", id: "username", label: "User" },
  { value: "createdAt", id: "createdAt", label: "Created At" },
  { value: "airtelProvider", id: "airtelProvider", label: "Airtel Provider" },
  {
    value: "payment",
    id: "pending_payment",
    label: "Pending Payment",
    editable: true,
  },
  {
    value: "2fa",
    id: "2fa",
    label: "2 Factor Authentication",
    editable: true,
  },
  {
    value: "loginStatus",
    id: "disableUser",
    label: "Login Status",
    editable: true,
  },
  { value: "autoEndStatus", id: "autoEnd", label: "Auto End", editable: true },
  { value: "firstSrc", id: "editSrcs", label: "Sources", editable: true },
  {
    value: "firstDests",
    id: "editDests",
    label: "Destinations",
    editable: true,
  },
  {
    value: "firstGeoMailId",
    dialog: "editMailIds",
    id: "editMailIds",
    label: "Geo Alert Mail Ids",
    editable: true,
  },
  {
    value: "userGeofenceSrc",
    dialog: "updateGeofence",
    id: "editSrc",
    label: "Geo Fence Src",
    editable: true,
  },
  {
    value: "userGeofenceDest",
    dialog: "updateGeofence",
    id: "editDest",
    label: "Geo Fence Dest",
    editable: true,
  },
  // { value: 'haltTime', id: 'haltTime', label: 'Halt Time', editable: true},
  {
    value: "firstHaltMailId",
    id: "haltAlertIds",
    label: "Halt Alert Ids",
    editable: true,
  },
  // { value: 'complianceTime', id: 'complianceTime', label: 'Compliance Time', editable: true},
  {
    value: "firstComplianceMailId",
    id: "complianceEmailIds",
    label: "Compliance Email Ids",
    editable: true,
  },
];

const email = new URLSearchParams(window.location.search).get("email") || null;
const specialUiAccess = [
  "rutvij@intugine.com",
  "ashutosh@intugine.com",
  "sangamtirtharaj@intugine.com",
  "aditya.agrawal@intugine.com",
  "pandurang@intugine.com",
  "mouryakota@intugine.com",
];
const displayHeaders = specialUiAccess?.includes(email)
  ? headers.slice(0, 5)
  : headers;

const defaultState = {
  //table data
  users: [],
  //filters
  search_by_user: "",
  search_by_client: "",
  //pagination
  page: 0,
  rowsPerPage: 10,
  total_users: 0,
  //taking action on which row(user) of table
  selectedRow: {},
  //reason for diabling user
  email: "",
  reason2fa: "",
  reason: "",
  //for editing autoend of a client
  autoend_when_options: ["geofence_in", "geofence_out"],
  //dialogs
  editMailIds: false,
  updateGeofence: false,
  editSrcs: false,
  editDests: false,
  autoEnd: false,
  "2fa": false,
  disableUser: false,
  //loading state
  loading: true,
};

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "70vh",
  },
});

export default function Home() {
  const classes = useStyles();
  const [state, setState] = React.useState(defaultState);
  const [facilitiesPopUp, setFacilitiesPopUp] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [clientOptions, setClientOptions] = useState([]);

  const handleTabChange = () => {
    if (tabValue === 0) setTabValue(1);
    else setTabValue(0);
  };

  const xlsxSampleData = [
    { header: "name", example: "KunalKP" },
    { header: "trip_location_type", example: "Source" },
    { header: "location", example: " 24.675, 72.090" },
  ];
  const xlsxSampleDataForIntracity = [
    { header: "name", example: "KunalKP" },
    { header: "radius (meters)", example: "500" },
    { header: "location", example: "24.675, 72.090" },
  ];

  const [facilitiesForm, setFacilitiesForm] = useState({
    dashboard: "",
    client: "",
    username: "",
    name: "",
    lat: "",
    location: "",
    radius: "",
  });

  useEffect(() => {
    if (tabValue === 1)
      setFacilitiesForm({ ...facilitiesForm, name: "", lat: "", location: "" });
  }, [tabValue]);
  const handleDownloadSampleXlsx = () => {
    if (facilitiesForm.dashboard === "Intracity") {
      convertJsonToExcel("Template", [
        xlsxSampleDataForIntracity?.map((item) => item?.header),
        xlsxSampleDataForIntracity?.map((item) => item?.example),
      ]);
    } else {
      convertJsonToExcel("Template", [
        xlsxSampleData?.map((item) => item?.header),
        xlsxSampleData?.map((item) => item?.example),
      ]);
    }
  };

  const [file, setFile] = useState(null);
  const [bulkUploadData, setBulkUploadData] = useState([]);
  useEffect(() => {
    if (file) {
      convertExcelToJson(file)?.then((res) => setBulkUploadData(res));
    }
  }, [file]);

  useEffect(() => {
    if (facilitiesForm?.dashboard)
      axios
        .get(
          `https://m43pgqobvj.execute-api.ap-south-1.amazonaws.com/get-clients?dashboard_type=${facilitiesForm.dashboard}`
        )
        .then((res) => setClientOptions(res.data?.results || []));
  }, [facilitiesForm.dashboard]);

  const handleUpload = () => {
    let data = {};
    if (tabValue === 0)
      data = {
        data: [
          {
            name: facilitiesForm?.name || undefined,
            updated_by: email || null,
            trip_location_type:
              facilitiesForm.location === "Both"
                ? ["SOURCE", "DESTINATION"]
                : facilitiesForm.location
                ? [facilitiesForm.location]
                : undefined,
            radius: facilitiesForm.radius ?? undefined,
            location: facilitiesForm.lat
              ? facilitiesForm.lat.split(",")
              : undefined,
          },
        ],
      };
    else
      data = {
        data: bulkUploadData?.map((item) =>
          facilitiesForm.dashboard === "Intracity"
            ? {
                name: item?.name,
                radius: item?.["radius (meters)"],
                location: item?.location?.split(","),
              }
            : {
                name: item?.name,
                trip_location_type:
                  item?.trip_location_type?.toLocaleUpperCase() === "BOTH"
                    ? ["SOURCE", "DESTINATION"]
                    : [item?.trip_location_type?.toLocaleUpperCase()],
                location: item?.location?.split(","),
              }
        ),
      };

    axios
      .post(
        "https://m43pgqobvj.execute-api.ap-south-1.amazonaws.com/add-facilities",
        data,
        {
          params: {
            dashboard_type: facilitiesForm.dashboard,
            client: facilitiesForm.client,
            username: facilitiesForm.username || undefined,
          },
        }
      )
      .then((res) => setFacilitiesPopUp(!facilitiesPopUp));
  };

  const appUser = (window.location.search.split("user=")[1] || "").split(
    "&"
  )[0];

  const changeHandler = (keys, values) => {
    setState((state) => {
      const newState = { ...state };
      keys.forEach((key, index) => {
        if (key === "selectedRow.disabled") {
          newState.selectedRow = {
            ...newState.selectedRow,
            disabled: values[index],
          };
        } else newState[key] = values[index];
      });
      return newState;
    });
  };

  const selectedRowChangeHandler = (keys, values) => {
    const newState = { ...state, selectedRow: { ...state.selectedRow } };
    keys.forEach((key, index) => {
      newState.selectedRow[key] = values[index];
    });
    setState(newState);
  };

  const init = (download_excel = false) => {
    changeHandler(["loading"], [true]);
    let users_url = `/users?skip=${
      download_excel ? 0 : state.page * state.rowsPerPage
    }&limit=${
      download_excel
        ? "all"
        : state.page * state.rowsPerPage + state.rowsPerPage
    }`;
    let sources_url = `/sources?skip=${
      download_excel ? 0 : state.page * state.rowsPerPage
    }&limit=${
      download_excel
        ? "all"
        : state.page * state.rowsPerPage + state.rowsPerPage
    }`;
    let alert_url = `/alert-info`;
    let autoend_url = `/autoend-info`;
    let compliance_url = `/compliance-info`;
    let halt_url = `/halt-info`;
    if (state.search_by_client) {
      users_url += `&client=${state.search_by_client}`;
      alert_url += `?client=${state.search_by_client}`;
      autoend_url += `?client=${state.search_by_client}`;
      compliance_url += `?client=${state.search_by_client}`;
      halt_url += `?client=${state.search_by_client}`;
    }
    if (state.search_by_user) users_url += `&username=${state.search_by_user}`;
    Promise.all([
      http({
        url: users_url,
      }),
      http({
        url: alert_url,
      }),
      http({
        url: autoend_url,
      }),
      http({
        url: compliance_url,
      }),
      http({
        url: halt_url,
      }),
      http({
        url: sources_url,
      }),
    ])
      .then((response) => {
        const alertInfoMap = {};
        const autoEndMap = {};
        const geofence = {};
        const complianceMap = {};
        const haltMap = {};
        const sourceMap = {};
        // console.log("response", response)
        response[1].data.results.forEach((info) => {
          alertInfoMap[info.user] = info;
        });
        response[2].data.results.forEach((info) => {
          if (!info.config.reachParams) {
            info.config.reachParams = {
              geofence: 5000,
              geofence_in: {
                trip_changes: {
                  endsIn: 21600000,
                },
              },
            };
            info.config.defaultAutoEnd = true;
          }
          autoEndMap[info.config.client + (info.config.client_client || "")] =
            autoEndMap[
              info.config.client + (info.config.client_client || "")
            ] || info;

          geofence[info._id] = geofence[info._id] || info;
        });
        response[3].data.results.forEach((info) => {
          complianceMap[
            info.config.client + (info.config.client_client || "")
          ] =
            complianceMap[
              info.config.client + (info.config.client_client || "")
            ] || info;
        });
        response[4].data.results.forEach((info) => {
          haltMap[info.config.client + (info.config.client_client || "")] =
            haltMap[info.config.client + (info.config.client_client || "")] ||
            info;
        });
        response[5].data.results.forEach((info) => {
          sourceMap[info._id] = sourceMap[info._id] || info;
        });
        // console.log("users data before update",response[0].data.results.users)
        const users = response[0].data.results.users
          ?.map((user) => {
            try {
              return {
                ...user,

                client: user?.config?.client,
                client_client: user.config?.client_client ?? "",

                loginStatus: user.disabled ? "Disable" : "Enable",
                "2fa": user?.config?.otp_auth ? "Disable" : "Enable", //if otp_auth is true the disable else enable
                payment: user.pending_payment ? "Disable" : "Enable",
                disabled: user?.disabled,

                allGeoFenceData: alertInfoMap[user.config?.client],
                userGeofenceSrc: user?.config?.tracking?.geofence_radius?.src ?? "" ,
                userGeofenceDest: user?.config?.tracking?.geofence_radius?.dest ?? "", 
                userGeofenceDrop: user?.config?.tracking?.geofence_radius?.drop ?? "" ,    
                geofenceSrc:  (alertInfoMap[user.config.client]?.geofenceAlertData?.src || ""),
                geofenceDest: (alertInfoMap[user.config.client]?.geofenceAlertData?.dest || ""),
                 
                firstGeoMailId:
                  alertInfoMap[user.config.client]?.geofenceAlertData
                    ?.alert_emails?.[0],
                alert_emails: Array.isArray(
                  alertInfoMap[user.config.client]?.geofenceAlertData
                    ?.alert_emails
                )
                  ? alertInfoMap[user.config.client].geofenceAlertData
                      .alert_emails
                  : [""],

                firstDests: (
                  ((user.config.trips?.data || { dest_locations: [] })
                    .dest_locations || [])?.[0] || { name: "NA" }
                ).name,
                newDests:
                  [
                    ...((user.config.trips?.data || { dest_locations: [] })
                      .dest_locations || []),
                  ] || [],

                // allSrcs: user.config.trips ? (user.config.trips.newtripinputfields || []).find(field => field.key === "srcname") : {list: []},
                // firstSrc: user.config.trips ? (((user.config.trips.newtripinputfields || []).find(field => field.key === "srcname") || {list: []}).list?.[0] || { name: "NA" }).name : [],
                // newSources: user.config.trips?.newtripinputfields?.find(field => field.key === "srcname")?.list || [],

                firstSrc:
                  sourceMap[user._id]?.config.trips?.newtripinputfields?.list?.[0]
                    ?.name || "NA",
                newSources:
                  sourceMap[user._id]?.config?.trips?.newtripinputfields?.list ||
                  [],

                haltTime: haltMap[
                  user.config.client + (user.config?.client_client || "")
                ]?.config.alerts?.halt?.haltParams
                  ?.map((param) => param?.durationThreshold)
                  .join(", "),
                firstHaltMailId:
                  haltMap[
                    user.config.client + (user.config?.client_client || "")
                  ]?.config.alerts.halt?.recipients?.client?.[0],
                allHaltEmails: haltMap[
                  user.config.client + (user.config?.client_client || "")
                ]?.config.alerts.halt?.recipients?.client || [""],
                allHaltData:
                  haltMap[
                    user.config.client + (user.config?.client_client || "")
                  ],

                firstComplianceMailId:
                  complianceMap[
                    user.config.client + (user.config?.client_client || "")
                  ]?.config?.reports?.tracking_success?.prod?.recipients?.[0],
                allComplianceEmails:
                  complianceMap[
                    user.config.client + (user.config?.client_client || "")
                  ]?.config?.reports?.tracking_success?.prod?.recipients || [],
                allcomplainceData:
                  complianceMap[
                    user.config.client + (user.config?.client_client || "")
                  ],

                autoEndStatus: autoEndMap[
                  user.config.client + (user.config?.client_client || "")
                ]
                  ? "Enable"
                  : "Disable",
                autoEndWhen: utils.getAutoEndWhen(
                  autoEndMap[
                    user.config.client + (user.config?.client_client || "")
                  ]
                ),
                autoEndTime:
                  geofence[
                    user._id
                    // user.config.client + (user.config.client_client || "")
                  ]?.config?.reachParams?.geofence_in?.trip_changes?.endsIn ??
                  1,
                autoEndGeofence:
                  geofence[
                    user._id
                    // user.config.client + (user.config.client_client || "")
                  ]?.config?.reachParams?.geofence ?? "",

                dropGeofenceRadius:
                  geofence[user._id]?.config?.tracking?.geofence_radius?.drop ??
                  "",
                autoEnd:
                  autoEndMap[
                    user.config.client + (user.config?.client_client || "")
                  ],
                airtelProvider: user?.config?.location_providers?.airtel?.service ?? ""
              };
            } catch {
              // alert(`Invalid data in ${user.username}`);
              // console.log("invalid user", user)
              return null;
            }
          })
          .filter((item) => item);
          // console.log("users",users)
        setState((state) => ({
          ...defaultState,
          users,
          total_users: response[0].data.results.total_users,
          search_by_client: state.search_by_client,
          search_by_user: state.search_by_user,
          page: state.page,
          rowsPerPage: state.rowsPerPage,
        }));
        if (download_excel) {
          const wb = xlsx.utils.book_new();
          const sheetdata = users.map((user) => {
            const obj = {};
            headers.forEach((header) => {
              if (header.label === "createdAt")
                obj[header.label] = moment(user[header.value]).format(
                  "DD-MM-YYYY @ hh:mm"
                );
              if (header.label === "Sources")
                obj[header.label] = user.newSources
                  .map((srcs) => srcs.name)
                  .join(",");
              if (header.label === "Destinations")
                obj[header.label] = user.newDests
                  .map((dest) => dest.name)
                  .join(",");
              if (header.label === "Geo Alert Mail Ids")
                obj[header.label] = user.alert_emails.join(",");
              if (header.label === "Compliance Email Ids")
                obj[header.label] = user.allComplianceEmails.join(",");
              if (header.label === "Halt Alert Ids")
                obj[header.label] = user.allHaltEmails.join(",");
              else obj[header.label] = user[header.value];
            });
            return obj;
          });
          const sheet = xlsx.utils.json_to_sheet(sheetdata);
          xlsx.utils.book_append_sheet(wb, sheet, "data");
          xlsx.writeFile(wb, "Users.xlsx");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally((_) => {
        changeHandler(["loading"], [false]);
      });
  };

  const twoFactorAuthentication = () => {
    const goAhead = window.confirm("Are you sure?");
    if (!goAhead) return;
    changeHandler(["loading"], [true]);

    http({
      method: "PATCH",
      params: {
        enable: state.selectedRow?.["2fa"] == "Enable" ? true : false, //if its enable- means 2fa is currently false.
        email: state?.email || null,
        reason: state?.reason2fa || null,
        submitted_by: email || null,
      },
      url: `twoFactorAuthentication/${state.selectedRow._id}`,
    })
      .then((response) => {
        console.log(response);
        init();
      })
      .catch((err) => {
        console.log(err);
        changeHandler(["loading"], [false]);
      })
      .finally((_) => {
        changeHandler(["loading"], [false]);
      });
  };
  const disableUser = () => {
    const goAhead = window.confirm("Are you sure?");
    if (!goAhead) return;
    changeHandler(["loading"], [true]);
    http({
      method: "PATCH",
      url: `/disable/${state.selectedRow._id}?reason=${state.reason}&status=${
        state.selectedRow.disabled
      }&submitted_by=${email || null}`,
    })
      .then((response) => {
        console.log(response);
        init();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally((_) => {
        changeHandler(["loading"], [false]);
      });
  };

  const updatehaltdata = () => {
    const goAhead = window.confirm("Are you sure?");
    if (!goAhead) return;
    changeHandler(["loading"], [true]);
    if (
      state.selectedRow?.allHaltData?.config?.alerts?.halt?.recipients?.client
    ) {
      const body = {
        client: state.selectedRow.config.client,
        halt: state.selectedRow.allHaltData.config.alerts.halt,
        updated_by: email || null,
      };
      body.halt.recipients.client = state.selectedRow.allHaltEmails;
      if (state.selectedRow.config.client_client)
        body.client_client = state.selectedRow.config.client_client;
      http({
        method: "PATCH",
        url: `/update-halt-data`,
        data: body,
      })
        .then((response) => {
          console.log(response);
          init();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally((_) => {
          changeHandler(["loading"], [false]);
        });
    } else {
      const body = {
        client: state.selectedRow.config.client,
        recipients: state.selectedRow.allHaltEmails,
        updated_by: email || null,
      };
      if (state.selectedRow.config.client_client)
        body.client_client = state.selectedRow.config.client_client;
      http({
        method: "POST",
        url: `/halt-alert`,
        data: body,
      })
        .then((response) => {
          console.log(response);
          init();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally((_) => {
          changeHandler(["loading"], [false]);
        });
    }
  };

  const updateCompliancedata = () => {
    const goAhead = window.confirm("Are you sure?");
    if (!goAhead) return;
    if (state.selectedRow.allcomplainceData) {
      const body = {
        client: state.selectedRow.config.client,
        recipients: state.selectedRow.allComplianceEmails,
        updated_by: email || null,
      };
      if (state.selectedRow.config.client_client)
        body.client_client = state.selectedRow.config.client_client;
      changeHandler(["loading"], [true]);
      http({
        method: "PATCH",
        url: `/update-compliance-data`,
        data: body,
      })
        .then((response) => {
          console.log(response);
          init();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally((_) => {
          changeHandler(["loading"], [false]);
        });
    } else {
      alert("Compliance Alert is not setup for this user");
    }
  };

  const updateGeofencedata = (param) => {
    const goAhead = window.confirm("Are you sure?");
    if (!goAhead) return;
    if (
      +state.selectedRow.geofenceSrc &&
      +state.selectedRow.geofenceDest &&
      state.selectedRow.alert_emails?.length
    ) {
      changeHandler(["loading"], [true]);
      if (state.selectedRow.allGeoFenceData) {
        http({
          method: "PATCH",
          url: `/update-geofence-data`,
          data: {
            _id: state.selectedRow.allGeoFenceData._id,
            alert_emails: state.selectedRow.alert_emails,
            src: +state.selectedRow.geofenceSrc,
            dest: +state.selectedRow.geofenceDest,
            appUser,
            updated_by: email || null,
          },
        })
          .then((response) => {
            console.log(response);
            init();
          })
          .catch((err) => {
            console.log(err);
          })
          .finally((_) => {
            changeHandler(["loading"], [false]);
          });
      } else {
        http({
          method: "POST",
          url: `/alert-info`,
          data: {
            alert_emails: state.selectedRow.alert_emails,
            src: +state.selectedRow.geofenceSrc,
            dest: +state.selectedRow.geofenceDest,
            user: state.selectedRow.client,
            client_client: state.selectedRow.client_client,
            appUser,
            updated_by: email || null,
          },
        })
          .then((response) => {
            console.log(response);
            init();
          })
          .catch((err) => {
            console.log(err);
          })
          .finally((_) => {
            changeHandler(["loading"], [false]);
          });
      }
    } else {
      alert("Invalid Data");
    }
  };

  const updateUserGeofencedata = (param) => {
    const goAhead = window.confirm("Are you sure?");
    if (!goAhead) return;
    if (
      +state.selectedRow.userGeofenceSrc &&
      +state.selectedRow.userGeofenceDest &&
      +state.selectedRow.userGeofenceDrop
    ) {
      changeHandler(["loading"], [true]);
      let temp_geofence = {}
      if(state.selectedRow.userGeofenceSrc)
      temp_geofence["src"] = parseInt(state.selectedRow.userGeofenceSrc)
      if(state.selectedRow.userGeofenceDest)
      temp_geofence["dest"] = parseInt(state.selectedRow.userGeofenceDest)
      if(state.selectedRow.userGeofenceDrop)
      temp_geofence["drop"] = parseInt(state.selectedRow.userGeofenceDrop)
        http({
          method: "PUT",
          url: `/update-trip-geofence`,
          data: {
          geofence: temp_geofence,
            client: state.selectedRow.client,
            client_client: state.selectedRow.client_client,
            appUser
          },
        })
          .then((response) => {
            console.log(response);
            init();
          })
          .catch((err) => {
            console.log(err);
          })
          .finally((_) => {
            changeHandler(["loading"], [false]);
          });
      
    } else {
      alert("Invalid Data");
    }
  };
  const disableAutoEnd = () => {
    const goAhead = window.confirm("Are you sure?");
    if (!goAhead) return;
    if (state.selectedRow.autoEnd) {
      changeHandler(["loading"], [true]);
      http({
        method: "PATCH",
        url: `/disable-autoend/`,
        data: {
          client: state.selectedRow.autoEnd.config.client,
          client_client: state.selectedRow.autoEnd.config.client_client,
          before: state.selectedRow.autoEnd,
          defaultAutoEnd: state.selectedRow.autoEnd.config.defaultAutoEnd,
          appUser,
          updated_by: email || null,
        },
      })
        .then((response) => {
          console.log(response);
          init();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally((_) => {
          changeHandler(["loading"], [false]);
        });
    }
  };

  const [selectWholeClient, setSelectWholeClient] = useState(false);

  const updateAutoEnd = () => {
    const goAhead = window.confirm("Are you sure?");

    if (!goAhead) return;
    if (
      state.selectedRow.autoEndGeofence &&
      state.selectedRow.autoEndTime &&
      +state.selectedRow.autoEndTime &&
      state.selectedRow.autoEndWhen
    ) {
      const not_when =
        state.selectedRow.autoEndWhen === "geofence_in"
          ? "geofence_out"
          : "geofence_in";
      const obj = {
        before: {
          ...(state.selectedRow.autoEnd?.config?.reachParams || {}),
          [not_when]: {
            ...(state.selectedRow.autoEnd?.config?.reachParams?.[not_when] ||
              {}),
            trip_changes: {
              ...(state.selectedRow.autoEnd?.config?.reachParams?.[not_when]
                ?.trip_changes || {}),
            },
          },
          [state.selectedRow.autoEndWhen]: {
            ...(state.selectedRow.autoEnd?.config?.reachParams?.[
              state.selectedRow.autoEndWhen
            ] || {}),
            trip_changes: {
              ...(state.selectedRow.autoEnd?.config?.reachParams?.[
                state.selectedRow.autoEndWhen
              ]?.trip_changes || {}),
            },
          },
        },
        after: {
          ...(state.selectedRow.autoEnd?.config?.reachParams || {}),
        },
      };
      if (obj.after[not_when]?.trip_changes?.endsIn)
        delete obj.after[not_when].trip_changes.endsIn;
      obj.after.geofence = +state.selectedRow.autoEndGeofence;
      obj.after[state.selectedRow.autoEndWhen] = {
        ...(obj.after[state.selectedRow.autoEndWhen] || {}),
        trip_changes: {
          ...(obj.after[state.selectedRow.autoEndWhen]?.trip_changes || {}),
          endsIn: +state.selectedRow.autoEndTime,
        },
      };
      changeHandler(["loading"], [true]);
      http({
        method: "PATCH",
        url: `autoend`,
        data: {
          client:
            state.selectedRow.autoEnd?.config?.client ??
            state.selectedRow.client,
          updated_by: email || null,
          client_client:
            state.selectedRow.autoEnd?.config?.client_client ??
            state.selectedRow.client_client,
          _id: selectWholeClient ? undefined : state.selectedRow?._id,
          appUser,
          update: obj,
          update_drops: {
            before: {
              geofence:
                state.selectedRow.autoEnd?.config?.tracking?.geofence_radius
                  .drop ?? 0,
            },
            after: {
              geofence:
                Number(state.selectedRow.dropGeofenceRadius) ??
                state.selectedRow.autoEnd?.config?.tracking?.geofence_radius
                  .drop,
            },
          },
        },
      })
        .then((response) => {
          console.log(response);
          init();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally((_) => {
          changeHandler(["loading"], [false]);
        });
      console.log(obj);
    }
  };

  const changeNewSource = (e, param, index) => {
    const value = e.target.value;
    const newsources = [...state.selectedRow.newSources];
    newsources[index] = {
      ...newsources[index],
      [param]: value,
    };
    selectedRowChangeHandler(["newSources"], [newsources]);
  };

  const changeNewAlertEmail = (e, param, index) => {
    const value = e.target.value;
    const alert_emails = [...state.selectedRow[param]];
    alert_emails[index] = value;
    selectedRowChangeHandler([param], [alert_emails]);
  };

  const changeNewDests = (e, param, index) => {
    const value = e.target.value;
    const newdests = [...state.selectedRow.newDests];
    newdests[index] = {
      ...newdests[index],
      [param]: value,
    };
    selectedRowChangeHandler(["newDests"], [newdests]);
  };

  const submitNewSources = () => {
    const goAhead = window.confirm("Are you sure?");
    if (!goAhead) return;
    changeHandler(["loading"], [true]);
    http({
      method: "PATCH",
      url: `sources`,
      data: {
        _id: state.selectedRow._id,
        appUser,
        sources: [...state.selectedRow.newSources],
        updated_by: email || null,
      },
    })
      .then((response) => {
        console.log(response);
        init();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally((_) => {
        changeHandler(["loading"], [false]);
      });
  };

  const submitNewDests = () => {
    const goAhead = window.confirm("Are you sure?");
    if (!goAhead) return;
    changeHandler(["loading"], [true]);
    http({
      method: "PATCH",
      url: `destinations`,
      data: {
        _id: state.selectedRow._id,
        appUser,
        destinations: [...state.selectedRow.newDests],
        updated_by: email || null,
      },
    })
      .then((response) => {
        console.log(response);
        init();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally((_) => {
        changeHandler(["loading"], [false]);
      });
  };

  const filter = () => {
    if (state.page !== 0) changeHandler(["page"], [0]);
    else init();
  };

  const downloadExcel = () => {
    init(true);
  };

  React.useEffect(() => {
    init();
  }, [state.page, state.rowsPerPage]);

  return (
    <Grid container style={{ padding: "20px" }}>
      {state.loading ? (
        <div
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            background: "rgba(0,0,0,0.6)",
            zIndex: 1400,
          }}
        >
          <CircularProgress />
        </div>
      ) : null}
      <Grid item style={{ padding: "5px 20px" }}>
        <TextField
          value={state.search_by_client}
          label="Search Client"
          variant="outlined"
          onChange={(e) =>
            changeHandler(["search_by_client"], [e.target.value])
          }
          fullWidth
        ></TextField>
      </Grid>
      <Grid item style={{ padding: "5px 20px" }}>
        <TextField
          value={state.search_by_user}
          label="Search User"
          variant="outlined"
          onChange={(e) => changeHandler(["search_by_user"], [e.target.value])}
          fullWidth
        ></TextField>
      </Grid>
      {/* <Grid item style={{padding: "5px 20px"}}>
                <TextField value={state.search_by_mailIds} label="Search Mail Ids" variant="outlined" onChange={(e) => changeHandler(["search_by_mailIds"], [e.target.value])} fullWidth></TextField>
            </Grid> */}
      <Grid item style={{ padding: "5px 20px" }}>
        <Button variant="contained" color="primary" onClick={filter}>
          Search
        </Button>
      </Grid>
      <Grid item style={{ padding: "5px 20px" }}>
        <Button color="primary" variant="contained" onClick={downloadExcel}>
          Download Excel
        </Button>
      </Grid>
      <Grid item style={{ padding: "5px 20px" }}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => setFacilitiesPopUp(!facilitiesPopUp)}
        >
          Add Facilities
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {displayHeaders.map((header) => (
                    <TableCell key={header.id} align="center">
                      {header.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {[...state.users]
                  .slice(
                    state.page * state.rowsPerPage,
                    state.page * state.rowsPerPage + state.rowsPerPage
                  )
                  .map((row) => (
                    <>
                      {/* {console.log("rows", row)} */}
                      <TableRow key={row._id}>
                        {displayHeaders.map((header) =>
                          header.editable ? (
                            <TableCell key={header.id} align="center">
                              <Button
                                variant="contained"
                                style={{ margin: 0 }}
                                onClick={
                                  header.id === "pending_payment"
                                    ? (e) => {
                                        const config = {
                                          client: [row.client],
                                          payment_status:
                                            row.payment !== "Disable"
                                              ? true
                                              : false,
                                        };

                                        axios.put(
                                          "https://sct.intutrack.com/api/v1/admin/toggle_pending_payment_popup",
                                          config,
                                          {
                                            headers: {
                                              "api-key": `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoibG92ZXByZWV0IiwiY2xpZW50IjoibG9`,
                                            },
                                          }
                                        );
                                        init();
                                      }
                                    : (e) =>
                                        changeHandler(
                                          [
                                            header.dialog || header.id,
                                            "selectedRow",
                                          ],
                                          [true, { ...row }]
                                        )
                                }
                              >
                                {row[header.value] || "NA"}
                              </Button>
                            </TableCell>
                          ) : header.id === "createdAt" ? (
                            <TableCell key={header.id} align="center">
                              {moment(row[header.value]).format(
                                "DD-MM-YYYY @ hh:mm"
                              )}
                            </TableCell>
                          ) : (
                            <TableCell key={header.id} align="center">
                              {row[header.value]}
                            </TableCell>
                          )
                        )}
                      </TableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100, 200, 250]}
            component="div"
            count={state.total_users}
            rowsPerPage={state.rowsPerPage}
            page={state.page}
            onChangePage={(e, newPage) => changeHandler(["page"], [newPage])}
            onChangeRowsPerPage={(event) =>
              changeHandler(["page", "rowsPerPage"], [0, +event.target.value])
            }
          />
        </Paper>
      </Grid>
      <Dialog
        open={state?.["2fa"]}
        onClose={() => changeHandler(["2fa", "selectedRow"], [false, {}])}
        fullWidth
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12} style={{ padding: "20px" }}>
            <Typography variant="h6">
              2 Factor Authentication {state.selectedRow.username} of{" "}
              {state.selectedRow.client}
              {console.log("oo bhai", state.selectedRow)}
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ padding: "5px 20px" }}>
            {/* if Enable is displayed it means its false value coming from the backend */}
            {state.selectedRow?.["2fa"] == "Enable" ? (
              <TextField
                value={state.email}
                label="Enter Email to Enable 2FA"
                variant="outlined"
                onChange={(e) => changeHandler(["email"], [e.target.value])}
                fullWidth
              ></TextField>
            ) : (
              <TextField
                value={state.reason2fa}
                label="Reason to disable 2FA"
                variant="outlined"
                onChange={(e) => changeHandler(["reason2fa"], [e.target.value])}
                fullWidth
              ></TextField>
            )}
          </Grid>
          <Grid item xs={12} style={{ padding: "20px" }}>
            <Button
              color="error"
              variant="contained"
              onClick={twoFactorAuthentication}
            >
              Yes
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => changeHandler(["2fa", "selectedRow"], [false, {}])}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        open={state.disableUser}
        onClose={() =>
          changeHandler(["disableUser", "selectedRow"], [false, {}])
        }
        fullWidth
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12} style={{ padding: "20px" }}>
            <Typography variant="h6">
              Disable/Enable {state.selectedRow.username} of{" "}
              {state.selectedRow.client}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ padding: "5px 20px" }}>
            Disabled:{" "}
            <Checkbox
              checked={state.selectedRow.disabled}
              onChange={(e) =>
                changeHandler(["selectedRow.disabled"], [e.target.checked])
              }
            ></Checkbox>
          </Grid>
          <Grid item xs={12} style={{ padding: "5px 20px" }}>
            <TextField
              value={state.reason}
              label="Reason"
              variant="outlined"
              onChange={(e) => changeHandler(["reason"], [e.target.value])}
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12} style={{ padding: "20px" }}>
            <Button color="error" variant="contained" onClick={disableUser}>
              Yes
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                changeHandler(["disableUser", "selectedRow"], [false, {}])
              }
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        open={state.haltAlertIds}
        onClose={() =>
          changeHandler(["haltAlertIds", "selectedRow"], [false, {}])
        }
        fullWidth
        maxWidth="sm"
      >
        <Grid container>
          <Grid item xs={12} style={{ padding: "20px" }}>
            <Typography variant="h6">
              Edit halt data of {state.selectedRow.username} of{" "}
              {state.selectedRow.client}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ padding: "5px 20px" }}>
            <ul>
              {(state.selectedRow.allHaltEmails || []).map((email, index) => (
                <li key={index}>
                  <TextField
                    label="name"
                    variant="outlined"
                    value={email}
                    onChange={(e) =>
                      changeNewAlertEmail(e, "allHaltEmails", index)
                    }
                  ></TextField>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      selectedRowChangeHandler(
                        ["allHaltEmails"],
                        [
                          state.selectedRow.allHaltEmails.filter(
                            (emails, i) => i !== index
                          ),
                        ]
                      )
                    }
                  >
                    Delete this
                  </Button>
                </li>
              ))}
            </ul>
          </Grid>
          <Grid item xs={12} style={{ padding: "20px" }}>
            <strong>
              This change will take effect for all users of client:{" "}
              {state.selectedRow.client}
            </strong>
          </Grid>
          <Grid item xs={12} style={{ padding: "20px" }}>
            <strong>
              This will only work if compliance is already setup for{" "}
              {state.selectedRow.client}
            </strong>
          </Grid>
          <Grid item xs={12} style={{ padding: "20px" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                selectedRowChangeHandler(
                  ["allHaltEmails"],
                  [[...state.selectedRow.allHaltEmails, ""]]
                )
              }
            >
              +
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                selectedRowChangeHandler(
                  ["allHaltEmails"],
                  [
                    [...state.selectedRow.allHaltEmails].splice(
                      0,
                      state.selectedRow.allHaltEmails.length - 1
                    ),
                  ]
                )
              }
            >
              -
            </Button>
            <Button color="error" variant="contained" onClick={updatehaltdata}>
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                changeHandler(["haltAlertIds", "selectedRow"], [false, {}])
              }
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        open={state.complianceEmailIds}
        onClose={() =>
          changeHandler(["complianceEmailIds", "selectedRow"], [false, {}])
        }
        fullWidth
        maxWidth="sm"
      >
        <Grid container>
          <Grid item xs={12} style={{ padding: "20px" }}>
            <Typography variant="h6">
              Edit complaince data of {state.selectedRow.username} of{" "}
              {state.selectedRow.client}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ padding: "5px 20px" }}>
            <ul>
              {(state.selectedRow.allComplianceEmails || []).map(
                (email, index) => (
                  <li key={index}>
                    <TextField
                      label="name"
                      variant="outlined"
                      value={email}
                      onChange={(e) =>
                        changeNewAlertEmail(e, "allComplianceEmails", index)
                      }
                    ></TextField>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        selectedRowChangeHandler(
                          ["allComplianceEmails"],
                          [
                            state.selectedRow.allComplianceEmails.filter(
                              (emails, i) => i !== index
                            ),
                          ]
                        )
                      }
                    >
                      Delete this
                    </Button>
                  </li>
                )
              )}
            </ul>
          </Grid>
          <Grid item xs={12} style={{ padding: "20px" }}>
            <strong>
              This change will take effect for all users of client:{" "}
              {state.selectedRow.client}
            </strong>
          </Grid>
          <Grid item xs={12} style={{ padding: "20px" }}>
            <strong>
              This will only work if compliance is already setup for{" "}
              {state.selectedRow.client}
            </strong>
          </Grid>
          <Grid item xs={12} style={{ padding: "20px" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                selectedRowChangeHandler(
                  ["allComplianceEmails"],
                  [[...state.selectedRow.allComplianceEmails, ""]]
                )
              }
            >
              +
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                selectedRowChangeHandler(
                  ["allComplianceEmails"],
                  [
                    [...state.selectedRow.allComplianceEmails].splice(
                      0,
                      state.selectedRow.allComplianceEmails.length - 1
                    ),
                  ]
                )
              }
            >
              -
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={updateCompliancedata}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                changeHandler(
                  ["complianceEmailIds", "selectedRow"],
                  [false, {}]
                )
              }
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        open={state.editMailIds}
        onClose={() =>
          changeHandler(["editMailIds", "selectedRow"], [false, {}])
        }
        fullWidth
        maxWidth="sm"
      >
        <Grid container>
          <Grid item xs={12} style={{ padding: "20px" }}>
            <Typography variant="h6">
              Edit geofence of {state.selectedRow.username} of{" "}
              {state.selectedRow.client}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ padding: "20px" }}>
            <TextField
              label="Source"
              variant="outlined"
              value={state.selectedRow.geofenceSrc}
              onChange={(e) =>
                selectedRowChangeHandler(["geofenceSrc"], [e.target.value])
              }
            ></TextField>
          </Grid>
          <Grid item xs={12} style={{ padding: "20px" }}>
            <TextField
              label="Destination"
              variant="outlined"
              value={state.selectedRow.geofenceDest}
              onChange={(e) =>
                selectedRowChangeHandler(["geofenceDest"], [e.target.value])
              }
            ></TextField>
          </Grid>
          <Grid item xs={12} style={{ padding: "5px 20px" }}>
            <ul>
              {(state.selectedRow.alert_emails || []).map((email, index) => (
                <li key={index}>
                  <TextField
                    label="name"
                    variant="outlined"
                    value={email}
                    onChange={(e) =>
                      changeNewAlertEmail(e, "alert_emails", index)
                    }
                  ></TextField>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      selectedRowChangeHandler(
                        ["alert_emails"],
                        [
                          state.selectedRow.alert_emails.filter(
                            (emails, i) => i !== index
                          ),
                        ]
                      )
                    }
                  >
                    Delete this
                  </Button>
                </li>
              ))}
            </ul>
          </Grid>
          <Grid item xs={12} style={{ padding: "20px" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                selectedRowChangeHandler(
                  ["alert_emails"],
                  [[...state.selectedRow.alert_emails, ""]]
                )
              }
            >
              +
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                selectedRowChangeHandler(
                  ["alert_emails"],
                  [
                    [...state.selectedRow.alert_emails].splice(
                      0,
                      state.selectedRow.alert_emails.length - 1
                    ),
                  ]
                )
              }
            >
              -
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={updateGeofencedata}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                changeHandler(["editMailIds", "selectedRow"], [false, {}])
              }
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Dialog>
      {/* Geofence update dialog start */}
      <Dialog
        open={state.updateGeofence}
        onClose={() =>
          changeHandler(["updateGeofence", "selectedRow"], [false, {}])
        }
        fullWidth
        maxWidth="sm"
      >
        <Grid container>
          <Grid item xs={12} style={{ padding: "20px" }}>
            <Typography variant="h6">
              Update geofence Radius of {state.selectedRow.username} of{" "}
              {state.selectedRow.client}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ padding: "20px" }}>
            <TextField
              label="Source"
              variant="outlined"
              type="number"
              value={state.selectedRow.userGeofenceSrc}
              onChange={(e) =>
                selectedRowChangeHandler(["userGeofenceSrc"], [e.target.value])
              }
            ></TextField>
          </Grid>
          <Grid item xs={12} style={{ padding: "20px" }}>
            <TextField
              label="Destination"
              variant="outlined"
              type="number"
              value={state.selectedRow.userGeofenceDest}
              onChange={(e) =>
                selectedRowChangeHandler(["userGeofenceDest"], [e.target.value])
              }
            ></TextField>
          </Grid>
          <Grid item xs={12} style={{ padding: "20px" }}>
            <TextField
              label="Drop"
              variant="outlined"
              type="number"
              value={state.selectedRow.userGeofenceDrop}
              onChange={(e) =>
                selectedRowChangeHandler(["userGeofenceDrop"], [e.target.value])
              }
            ></TextField>
          </Grid>
         
          <Grid item xs={12} style={{ padding: "20px" }}>
           
          
            <Button
              color="error"
              variant="contained"
              onClick={updateUserGeofencedata}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                changeHandler(["updateGeofence", "selectedRow"], [false, {}])
              }
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Dialog>
      {/* Geofence update dialog end */}
      <Dialog
        open={state.editSrcs}
        onClose={() => changeHandler(["editSrcs", "selectedRow"], [false, {}])}
        fullWidth
        maxWidth="md"
      >
        <Grid container>
          <Grid item xs={12} style={{ padding: "20px" }}>
            <Typography variant="h6">
              Add New Sources to {state.selectedRow.username} of{" "}
              {state.selectedRow.client}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ padding: "5px 20px" }}>
            <ol>
              {(state.selectedRow.newSources || []).map((src, index) => (
                <li key={index} style={{ margin: "20px 0" }}>
                  <TextField
                    label="name"
                    variant="outlined"
                    value={src.name}
                    onChange={(e) => changeNewSource(e, "name", index)}
                  ></TextField>
                  <TextField
                    label="loc"
                    variant="outlined"
                    value={src.loc}
                    onChange={(e) => changeNewSource(e, "loc", index)}
                  ></TextField>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      selectedRowChangeHandler(
                        ["newSources"],
                        [
                          state.selectedRow.newSources.filter(
                            (src, i) => i !== index
                          ),
                        ]
                      )
                    }
                  >
                    Delete this
                  </Button>
                </li>
              ))}
            </ol>
          </Grid>
          <Grid item xs={12} style={{ padding: "5px 20px 10px 20px" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                selectedRowChangeHandler(
                  ["newSources"],
                  [[...state.selectedRow.newSources, { name: "", loc: "" }]]
                )
              }
            >
              +
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                selectedRowChangeHandler(
                  ["newSources"],
                  [
                    [...state.selectedRow.newSources].splice(
                      0,
                      state.selectedRow.newSources.length - 1
                    ),
                  ]
                )
              }
            >
              -
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={submitNewSources}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                changeHandler(["editSrcs", "selectedRow"], [false, {}])
              }
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        open={state.editDests}
        onClose={() => changeHandler(["editDests", "selectedRow"], [false, {}])}
        fullWidth
        maxWidth="md"
      >
        <Grid container>
          <Grid item xs={12} style={{ padding: "20px" }}>
            <Typography variant="h6">
              Add New Destinations to {state.selectedRow.username} of{" "}
              {state.selectedRow.client}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ padding: "5px 20px" }}>
            <ol>
              {(state.selectedRow.newDests || []).map((dest, index) => (
                <li key={index} style={{ margin: "20px 0" }}>
                  <TextField
                    label="source"
                    variant="outlined"
                    value={dest.name}
                    onChange={(e) => changeNewDests(e, "name", index)}
                  ></TextField>
                  <TextField
                    label="loc"
                    variant="outlined"
                    value={dest.loc}
                    onChange={(e) => changeNewDests(e, "loc", index)}
                  ></TextField>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      selectedRowChangeHandler(
                        ["newDests"],
                        [
                          state.selectedRow.newDests.filter(
                            (dest, i) => i !== index
                          ),
                        ]
                      )
                    }
                  >
                    Delete this
                  </Button>
                </li>
              ))}
            </ol>
          </Grid>
          <Grid item xs={12} style={{ padding: "5px 20px 10px 20px" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                selectedRowChangeHandler(
                  ["newDests"],
                  [[...state.selectedRow.newDests, { name: "", loc: "" }]]
                )
              }
            >
              +
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                selectedRowChangeHandler(
                  ["newDests"],
                  [
                    [...state.selectedRow.newDests].splice(
                      0,
                      state.selectedRow.newDests.length - 1
                    ),
                  ]
                )
              }
            >
              -
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={submitNewDests}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                changeHandler(["editDests", "selectedRow"], [false, {}])
              }
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        open={state.autoEnd}
        onClose={() => {
          changeHandler(["autoEnd", "selectedRow"], [false, {}]);
          setSelectWholeClient(false);
        }}
        fullWidth
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12} style={{ padding: "20px" }}>
            <Typography variant="h6">
              Enable/Disable Autoend of {state.selectedRow.username} of{" "}
              {state.selectedRow.client}
            </Typography>
          </Grid>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              color="primary"
              checked={selectWholeClient}
              onChange={(e) => setSelectWholeClient(e.target.checked)}
            />
            <Typography>Select Whole Client</Typography>
          </Box>
          <Grid item xs={12} style={{ padding: "20px" }}>
            Autoend Status: {state.selectedRow.autoEndStatus}
          </Grid>
          <Grid item xs={12} style={{ padding: "20px" }}>
            Autoend on?
            <TextField
              variant="outlined"
              select
              fullWidth
              value={state.selectedRow.autoEndWhen}
              onChange={(e) =>
                selectedRowChangeHandler(["autoEndWhen"], [e.target.value])
              }
            >
              <MenuItem value="geofence_in">Geofence In</MenuItem>
              <MenuItem value="geofence_out">Geofence Out</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} style={{ padding: "20px" }}>
            Autoend geofence?
            <TextField
              variant="outlined"
              fullWidth
              value={state.selectedRow.autoEndGeofence}
              onChange={(e) =>
                selectedRowChangeHandler(["autoEndGeofence"], [e.target.value])
              }
            ></TextField>
          </Grid>
          <Grid item xs={12} style={{ padding: "20px" }}>
            Drop Geofence Radius
            <TextField
              variant="outlined"
              fullWidth
              inputProps={{ pattern: "[a-z]{1,15}" }}
              value={state.selectedRow.dropGeofenceRadius}
              onChange={(e) =>
                selectedRowChangeHandler(
                  ["dropGeofenceRadius"],
                  [e.target.value.replace(/\D/g, "")]
                )
              }
            ></TextField>
          </Grid>
          <Grid item xs={12} style={{ padding: "20px" }}>
            Autoend time? <span>(can't be 0)</span>{" "}
            <span>(in miliseconds)</span>
            <TextField
              variant="outlined"
              fullWidth
              value={state.selectedRow.autoEndTime}
              onChange={(e) =>
                selectedRowChangeHandler(["autoEndTime"], [e.target.value])
              }
            ></TextField>
          </Grid>
          <Grid item xs={12} style={{ padding: "20px" }}>
            <strong>
              This change will take effect for all users of client:{" "}
              {state.selectedRow.client}
            </strong>
          </Grid>
          <Grid item xs={12} style={{ padding: "20px" }}>
            <Button color="primary" variant="contained" onClick={updateAutoEnd}>
              Edit
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={disableAutoEnd}
            >
              Disable
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                changeHandler(["autoEnd", "selectedRow"], [false, {}])
              }
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        open={facilitiesPopUp}
        onClose={() => setFacilitiesPopUp(!facilitiesPopUp)}
        fullWidth
        maxWidth="sm"
      >
        <div style={{ height: "500px" }}>
          <Grid
            item
            style={{
              padding: "20px",
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <TextField
                label="Select Dashboard"
                variant="outlined"
                placeholder="select"
                select
                onChange={(e) =>
                  setFacilitiesForm({
                    ...facilitiesForm,
                    dashboard: e.target.value,
                  })
                }
                style={{ width: "200px" }}
              >
                <MenuItem value="SCT">SCT</MenuItem>
                <MenuItem value="Intracity">Intracity</MenuItem>
              </TextField>

              <Autocomplete
                options={clientOptions.filter((item) => item)}
                getOptionLabel={(option) => option}
                label="Select Client"
                placeholder="select"
                variant="outlined"
                style={{ width: "200px" }}
                disabled={!facilitiesForm.dashboard}
                onChange={(e, v) =>
                  setFacilitiesForm({
                    ...facilitiesForm,
                    client: v,
                  })
                }
                renderOption={(option) => <span>{option}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Client"
                    placeholder="select"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              {facilitiesForm.dashboard !== "Intracity" && (
                <TextField
                  label="Username"
                  placeholder="Enter username"
                  variant="outlined"
                  style={{ width: "200px" }}
                  value={facilitiesForm.username}
                  onChange={(e) =>
                    setFacilitiesForm({
                      ...facilitiesForm,
                      username: e.target.value,
                    })
                  }
                ></TextField>
              )}
            </Box>
            <Box>
              <Tabs
                value={!tabValue ? 0 : 1}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
                centered
              >
                <Tab label="Single Upload" />
                <Tab label="Bulk Upload" />
              </Tabs>
            </Box>
            {!tabValue ? (
              <Box
                style={{
                  height: "320px",
                }}
              >
                <Grid
                  container
                  justify="space-between"
                  style={{ width: "100%", paddingTop: "8px" }}
                >
                  <Grid item>
                    <TextField
                      style={{ width: "250px" }}
                      label="Name"
                      value={facilitiesForm.name}
                      variant="outlined"
                      fullWidth
                      onChange={(e) =>
                        setFacilitiesForm({
                          ...facilitiesForm,
                          name: e.target.value,
                        })
                      }
                    ></TextField>
                  </Grid>
                  <Grid item>
                    <TextField
                      style={{ width: "250px" }}
                      label="Location"
                      value={facilitiesForm.lat}
                      placeholder="lat, long"
                      variant="outlined"
                      fullWidth
                      onChange={(e) =>
                        setFacilitiesForm({
                          ...facilitiesForm,
                          lat: e.target.value,
                        })
                      }
                    ></TextField>
                  </Grid>
                </Grid>

                <Grid
                  container
                  justify="space-between"
                  style={{ width: "100%", paddingTop: "80px" }}
                >
                  <Grid item>
                    {facilitiesForm.dashboard !== "Intracity" ? (
                      <TextField
                        label="Location Type"
                        variant="outlined"
                        select
                        value={facilitiesForm.location}
                        style={{ width: "250px" }}
                        onChange={(e) =>
                          setFacilitiesForm({
                            ...facilitiesForm,
                            location: e.target.value,
                          })
                        }
                      >
                        <MenuItem value="SOURCE">Source</MenuItem>
                        <MenuItem value="DESTINATION">Destination</MenuItem>
                        <MenuItem value="Both">Both</MenuItem>
                      </TextField>
                    ) : (
                      <TextField
                        label="Enter Geofence (meters)"
                        placeholder="500"
                        variant="outlined"
                        style={{ width: "250px" }}
                        value={facilitiesForm.radius}
                        onChange={(e) =>
                          setFacilitiesForm({
                            ...facilitiesForm,
                            radius: e.target.value,
                          })
                        }
                      ></TextField>
                    )}
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box
                style={{
                  height: "320px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <input
                  type="file"
                  accept=".xlsx"
                  onChange={(e) => setFile(e.target.files[0])}
                />
                <p
                  onClick={handleDownloadSampleXlsx}
                  style={{
                    color: "blue",
                    cursor: "pointer",
                  }}
                >
                  Click here to download sample Bulk Upload file
                </p>
              </Box>
            )}
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {" "}
              <Button
                color="primary"
                variant="contained"
                onClick={handleUpload}
                disabled={!facilitiesForm.dashboard || !facilitiesForm.client}
              >
                Upload
              </Button>
            </Box>
          </Grid>
        </div>
      </Dialog>
    </Grid>
  );
}
