import Navbar from './components/navbar/navbar';
import Home from './components/home/home';

function App() {
  return (
    <div className="App" style={{position: "relative", minHeight: "100vh"}}>
      <Navbar />
      <Home />
    </div>
  );
}

export default App;
